import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

const GetAllPropertyList = () => {
  const navigate = useNavigate();
  const [searchTest, setsearchTest] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [propertyData, setPropertyData] = useState([]);
  const [propertyCount, setPropertyCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + propertyData.length - 1,
    currentPage * itemsPerPage
  );
  const [propertySorting, setPropertySorting] = useState("createdDate");
  const [propertyReverse, setPropertyReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const hasToastShownRef = useRef(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showReConfirmDialog, setShowReConfirmDialog] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const initialFormData = {
    propertyName: "",
    propertyLocation: "",
    propertyDescription: "",
  };

  const [propertyFormData, setPropertyFormData] = useState(initialFormData);
  const resetForm = () => setPropertyFormData(initialFormData);
  const [deletingListData, setDeletingListData] = useState(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isDeleteReConfirmationOpen, setDeleteReConfirmationOpen] =
    useState(false);
  const [showOuListPopup, setShowOuListPopup] = useState(false);

  useEffect(() => {
    const fetchListData = async () => {
      await fetchPropertyData(currentPage);
    };
    fetchListData();
  }, [currentPage, propertySorting, propertyReverse]);

  useEffect(() => {
    if (searchInputValue === "") {
      setCurrentPage(1);
      fetchPropertyData(1);
    }
  }, [searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false);
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1);
      fetchPropertyData(1);
      setIsSearchButtonClicked(false);
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    if (propertyData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [propertyData]);

  useEffect(() => {
    if (searchTest) {
      setCurrentPage(1);
      fetchPropertyData(1);
      setIsSearchButtonClicked(false);
    }
  }, [searchTest]);

  const handleCreateButtonClick = () => {
    navigate(`/create?form=PropertyMaster`);
  };

  const handleUpdateClick = (item) => {
    setSelectedProperty(item);
    setShowUpdateForm(true);
  };

  const handleUpdateComplete = () => {
    setCurrentPage(1);
    fetchPropertyData(1);
  };

  const exportPropertyList = async () => {
    try {
      const response = await fetch(serverUrl + "registeredProperties/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: searchTest || "",
          sortBycolumn: propertySorting,
          sortByFlag: propertyReverse,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Pagination Function
  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }
    setCurrentPage(newPage);
  };

  const handleouPropertySort = (newSortBy) => {
    setPropertyReverse((prevReverse) => !prevReverse);
    setPropertySorting(newSortBy);
    setCurrentPage(1);
  };

  const handleNoResultsFound = () => {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;

      toastWarning("No Property Found", 3000);
      setPropertyData([]);
    }

    setPropertyCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  const fetchPropertyData = async (page) => {
    setIsLoadingListData(true);
    try {
      const response = await fetch(serverUrl + "/get/Property", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: searchTest || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage,
          sortBycolumn: propertySorting || "createdDate",
          sortByFlag: propertyReverse || false,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.PropertyResultsList != null &&
            data.entity.PropertyResultsList.length > 0
          ) {
            setPropertyData(data.entity.PropertyResultsList || []);
            setPropertyCount(data.entity.totalCount || 0);
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching Organization Property data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleAddClick = (e) => {
    e.preventDefault();
    setShowConfirmDialog(true);
    setShowUpdateForm(false);
  };

  const handleConfirm = () => {
    setShowConfirmDialog(false);
    setShowReConfirmDialog(true);
  };

  const handleCancelDilog = () => {
    setShowConfirmDialog(false);
    resetForm();
  };

  const handleCancelDilogBox = () => {
    setShowReConfirmDialog(false);
    resetForm();
  };

  const showSuccessModelBox = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
    handleUpdateComplete();
    resetForm();
  };

  const handleReConfirm = () => {
    updateProperty();
    setShowReConfirmDialog(false);
  };

  const handleCancleClick = (e) => {
    setShowUpdateForm(false);
    resetForm();
  };

  const updateProperty = async () => {
    setIsLoadingListData(true);
    try {
      let body = {
        propertyId: selectedProperty.id,
      };

      if (propertyFormData.propertyName) {
        body.propertyName = propertyFormData.propertyName;
      }

      if (propertyFormData.propertyLocation) {
        body.propertyLocation = propertyFormData.propertyLocation;
      }

      if (propertyFormData.propertyDescription) {
        body.propertyDescription = propertyFormData.propertyDescription;
      }

      const response = await fetch(serverUrl + "save/Property", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Failed to send data");
      }
    } catch (error) {
      console.error("Error sending data:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const isFormValid = () => {
    return (
      propertyFormData.propertyName ||
      propertyFormData.propertyLocation ||
      propertyFormData.propertyDescription
    );
  };

  const openDeleteConfirmation = (e, dataToDelete) => {
    e.stopPropagation();
    setDeletingListData(dataToDelete);
    // setVehicleTestCount(vehicleTestCount); // Set the vehicle count
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const confirmDelete = async (e) => {
    if (deletingListData) {
      try {
        const response = await fetch(serverUrl + "/property/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            propertyMasterId: deletingListData.id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.entity != null) {
            toastSuccess("Successfully Deleted");
            const updatedItems = propertyData.filter(
              (property) => property.id !== deletingListData.id
            );
            setPropertyData([...updatedItems]);
            setCurrentPage(1);
            fetchPropertyData(1);
          } else if (data.errors != null) {
            let errorDescription = data.errors.errorDescription;
            toastWarning(errorDescription, 3000);
            return;
          } else {
            toastWarning("Something Went Wrong", 3000);
            return;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const toViewAssignedProperties = () => {
    navigate(`/getAllOuPropertyList`);
  };

  const handleOuListOpenPopup = (item) => {
    setSelectedProperty(item);
    setShowOuListPopup(true);
  };

  const handleOUViewButtonClick = () => {
    navigate("/getAllOuPropertyList");
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text"></p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <div className="px-0 mx-0">
            <ToastContainer />
            <div className="row px-0 mx-0 d-flex">
              <div className="col-md-12 mx-0 px-0">
                <Header />
                <div className="row px-2 mx-0">
                  <div
                    className="px-0 my-2"
                    style={{ backgroundColor: "white", borderRadius: "8px" }}
                  >
                    <div
                      className="card-container-test mx-0 mt-2 px-2 pb-3 "
                      style={{ maxheight: "100" }}
                    >
                      <div className="px-0 d-flex justify-content-between">
                        <div className="d-flex mb-2">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={searchInputValue}
                              onChange={handleInputChange}
                              style={{
                                width: "25vw",
                                borderRadius: "5px",
                              }}
                            />
                            {searchInputValue && (
                              <FontAwesomeIcon
                                icon={faXmark}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "30%",
                                  color: "gray",
                                }}
                                onClick={() => {
                                  setsearchTest("");
                                  setSearchInputValue("");
                                }}
                              />
                            )}
                          </div>

                          <Button
                            variant="primary"
                            onClick={handleSearch}
                            className="btns mx-1"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>

                        <div className="justify-content-end">
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={handleCreateButtonClick}
                          >
                            Create
                          </Button>
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={toViewAssignedProperties}
                          >
                            Assigned Properites
                          </Button>
                          <Button
                            variant="primary"
                            className="mx-1 mb-2 btns"
                            onClick={exportPropertyList}
                            disabled={propertyData.length === 0}
                          >
                            Export
                          </Button>
                        </div>
                      </div>

                      {/* Delete Modal */}
                      <Modal
                        show={isDeleteConfirmationOpen}
                        onHide={closeDeleteConfirmation}
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>Are you sure you want to delete this property?</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btns"
                            variant="secondary"
                            onClick={closeDeleteConfirmation}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btns"
                            variant="danger"
                            onClick={(e) => {
                              closeDeleteConfirmation();
                              setDeleteReConfirmationOpen(true);
                              closeDeleteConfirmation();
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* Re-confirmation Modal */}
                      <Modal
                        show={isDeleteReConfirmationOpen}
                        onHide={() => setDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Reconfirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            {" "}
                            Are you sure you want to delete this property? This
                            action is irreversible.
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              confirmDelete(e);
                              setDeleteReConfirmationOpen(false);
                            }}
                          >
                            Confirm Delete
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => setDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                          >
                            Cancel
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <table>
                            <thead className="fixed-header">
                              <tr>
                                <th>Sr. No</th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("propertyName")
                                  }
                                >
                                  Property Name&nbsp;
                                  {propertySorting === "propertyName" && (
                                    <FontAwesomeIcon
                                      icon={
                                        propertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("propertyLocation")
                                  }
                                >
                                  Property Location&nbsp;
                                  {propertySorting === "propertyLocation" && (
                                    <FontAwesomeIcon
                                      icon={
                                        propertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("propertyDescription")
                                  }
                                >
                                  Property Description&nbsp;
                                  {propertySorting ===
                                    "propertyDescription" && (
                                    <FontAwesomeIcon
                                      icon={
                                        propertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th className="text-center">
                                  Organization Count
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("createdDate")
                                  }
                                >
                                  Created Date&nbsp;
                                  {propertySorting === "createdDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        propertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>

                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleouPropertySort("updatedDate")
                                  }
                                >
                                  Updated Date&nbsp;
                                  {propertySorting === "updatedDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        propertyReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {propertyData.map((property, index) => (
                                <tr
                                  className="clickable-row"
                                  key={property.id}
                                  style={{
                                    borderBottom: "1px solid #ddd",
                                    backgroundColor: property.isHighlight
                                      ? "#f5f5a5"
                                      : "transparent",
                                  }}
                                >
                                  <td>{startingSerialNumber + index}</td>

                                  <td className="text-left">
                                    {property.propertyName || "-"}
                                  </td>

                                  <td className="text-left">
                                    {property.propertyLocation || "-"}
                                  </td>

                                  <td
                                    className="text-left"
                                    style={{
                                      wordWrap: "break-word",
                                      maxWidth: "500px",
                                      whiteSpace: "normal",
                                    }}
                                  >
                                    {property.propertyDescription || "-"}
                                  </td>

                                  <td className="text-center">
                                    <Button
                                      className="mx-1 btns"
                                      variant="primary"
                                      disabled={property.ouMaster.length === 0}
                                      onClick={(e) =>
                                        handleOuListOpenPopup(property)
                                      }
                                    >
                                      View [{property.ouMaster.length}]
                                    </Button>
                                  </td>

                                  <td className="text-left">
                                    {new Date(
                                      property.createdDate
                                    ).toLocaleDateString("en-GB")}
                                  </td>

                                  <td className="text-left">
                                    {property.updatedDate
                                      ? new Date(
                                          property.updatedDate
                                        ).toLocaleDateString("en-GB")
                                      : "-"}
                                  </td>

                                  <td onClick={(e) => e.stopPropagation()}>
                                    <div
                                      style={{ display: "flex", gap: "25px" }}
                                    >
                                      <button
                                        style={{
                                          color: "black",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          handleUpdateClick(property)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button>
                                      <button
                                        className="delete-btn btns"
                                        style={{
                                          color:
                                            property.ouMaster.length > 0
                                              ? "grey"
                                              : "black", // Change color if disabled
                                          border: "none",
                                          cursor:
                                            property.ouMaster.length > 0
                                              ? "not-allowed"
                                              : "pointer", // Show 'not-allowed' cursor
                                        }}
                                        onClick={(e) =>
                                          openDeleteConfirmation(e, property)
                                        }
                                        disabled={property.ouMaster.length > 0} // Disable if there are OUs assigned
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          style={{
                                            color:
                                              property.ouMaster.length > 0
                                                ? "lightgrey"
                                                : "black", // Change icon color if disabled
                                          }}
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        {!isLoadingListData && propertyData.length === 0 && (
                          <div
                            style={{
                              textAlign: "center",
                              marginTop: "15px",
                            }}
                          >
                            <img src={Notfound} alt="Not Found" />
                            <p
                              style={{
                                marginTop: "-10px",
                                fontWeight: "bold",
                              }}
                            >
                              No Property Found
                            </p>
                          </div>
                        )}
                      </div>

                      {!isLoadingListData && propertyData.length > 0 && (
                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                          <div className="pagination-buttons">
                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === 1 || propertyData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("prev")}
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>

                            <div className="pagination-info">
                              <p>
                                {propertyData.length > 0 ? rangeStart : 0} -{" "}
                                {propertyData.length > 0 ? rangeEnd : 0} of{" "}
                                {propertyData.length > 0 ? propertyCount : 0}{" "}
                              </p>
                            </div>

                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === totalPages ||
                                  propertyData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("next")}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <Modal
                        show={showUpdateForm}
                        onHide={handleCancleClick}
                        // dialogClassName="my-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="text-center">
                            Update Property
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedProperty && (
                            <Form>
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="propertyLocation"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Property Location
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="propertyLocation"
                                    id="propertyLocation"
                                    value={propertyFormData.propertyLocation}
                                    onChange={(e) =>
                                      setPropertyFormData((prev) => ({
                                        ...prev,
                                        propertyLocation: e.target.value,
                                      }))
                                    }
                                    placeholder="Enter Property Location"
                                  />
                                  {selectedProperty.propertyLocation && (
                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {selectedProperty.propertyLocation}
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="propertyDescription"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Property Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="propertyDescription"
                                    id="propertyDescription"
                                    value={propertyFormData.propertyDescription}
                                    onChange={(e) =>
                                      setPropertyFormData((prev) => ({
                                        ...prev,
                                        propertyDescription: e.target.value,
                                      }))
                                    }
                                    placeholder="Enter Property Description"
                                  />
                                  {selectedProperty.propertyDescription && (
                                    <div
                                      style={{
                                        fontSize: "1rem",
                                        color: "#6c757d",
                                        marginTop: "2px",
                                      }}
                                    >
                                      {selectedProperty.propertyDescription}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Form>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btn btn-secondary"
                            onClick={(e) => handleCancleClick(e)}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btn btn-primary"
                            onClick={(e) => handleAddClick(e)}
                            disabled={!isFormValid()}
                          >
                            Update
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      <Modal
                        show={showConfirmDialog}
                        onHide={handleCancelDilog}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <>Are you sure you want to Update this Property?</>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCancelDilog}
                          >
                            Cancel
                          </Button>
                          <Button variant="primary" onClick={handleConfirm}>
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal
                        show={showReConfirmDialog}
                        onHide={handleCancelDilogBox}
                        dialogClassName="my-modal"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Reconfirm Update</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          {selectedProperty && (
                            <div style={{ marginTop: "20px" }}>
                              <div
                                style={{
                                  marginBottom: "15px",
                                  fontSize: "1.2rem",
                                }}
                              >
                                Do you really want to apply these modifications
                                to the Property?
                              </div>
                              <table
                                className="table no-border"
                                style={{
                                  borderCollapse: "collapse",
                                  width: "100%",
                                  textAlign: "left",
                                }}
                              >
                                <thead>
                                  <tr>
                                    <th style={{ textAlign: "left" }}>Field</th>
                                    <th style={{ textAlign: "left" }}>
                                      Current
                                    </th>
                                    <th style={{ textAlign: "left" }}>New</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {propertyFormData.propertyName && (
                                    <tr>
                                      <td style={{ textAlign: "left" }}>
                                        Property Name
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {selectedProperty.propertyName}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {propertyFormData.propertyName}
                                      </td>
                                    </tr>
                                  )}

                                  {propertyFormData.propertyLocation && (
                                    <tr>
                                      <td style={{ textAlign: "left" }}>
                                        Property Location
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {selectedProperty.propertyLocation}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {propertyFormData.propertyLocation}
                                      </td>
                                    </tr>
                                  )}

                                  {propertyFormData.propertyDescription && (
                                    <tr>
                                      <td style={{ textAlign: "left" }}>
                                        Property Description
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {selectedProperty.propertyDescription}
                                      </td>
                                      <td style={{ textAlign: "left" }}>
                                        {propertyFormData.propertyDescription}
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCancelDilogBox}
                          >
                            Cancel
                          </Button>
                          <Button variant="primary" onClick={handleReConfirm}>
                            Confirm
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* success Dialog Box*/}
                      <Modal
                        show={isSuccessModalVisible}
                        onHide={handleCloseSuccessModal}
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Success</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <>Property Updated successfully..!</>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={handleCloseSuccessModal}
                          >
                            Close
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <Modal
                        show={showOuListPopup}
                        onHide={() => setShowOuListPopup(false)}
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>
                            {selectedProperty?.ouMaster?.length === 1
                              ? `Organization Using ${
                                  selectedProperty?.propertyName ?? "this"
                                } Property`
                              : `Organizations Using ${
                                  selectedProperty?.propertyName ?? "this"
                                } Property`}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div
                            className="mb-2"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <table className="table">
                              <thead>
                                <tr>
                                  <th
                                    style={{ border: "1px solid black" }}
                                    className="table-head"
                                  >
                                    Sr. No.
                                  </th>
                                  <th
                                    style={{ border: "1px solid black" }}
                                    className="text-left"
                                  >
                                    Organization Name
                                  </th>
                                  {/* <th
                                    style={{ border: "1px solid black" }}
                                    className="table-head"
                                  >
                                    Action
                                  </th> */}
                                </tr>
                              </thead>
                              <tbody style={{ color: "black" }}>
                                {!isLoadingListData &&
                                selectedProperty &&
                                selectedProperty.ouMaster.length > 0 ? (
                                  selectedProperty.ouMaster.map(
                                    (ou, ouIndex) => (
                                      <tr
                                        className="clickable-row"
                                        key={ou.id}
                                        style={{
                                          borderBottom: "1px solid #ddd",
                                        }}
                                      >
                                        {/* Serial number based on ouIndex */}
                                        <td
                                          style={{ border: "1px solid black" }}
                                        >
                                          {startingSerialNumber + ouIndex}
                                        </td>
                                        {/* Display the OU name */}
                                        <td
                                          className="text-left"
                                          style={{ border: "1px solid black" }}
                                        >
                                          {ou.ouName}
                                        </td>
                                        {/* Action button */}
                                        {/* <td
                                              className="text-center"
                                              style={{ border: "1px solid black" }}
                                            >
                                              <Button
                                                className="mx-1 btns"
                                                variant="primary"
                                                onClick={handleOUViewButtonClick}
                                              >
                                                View
                                              </Button>
                                            </td> */}
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan="3"
                                      style={{ textAlign: "center" }}
                                    >
                                      No Property Assigned
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GetAllPropertyList;
