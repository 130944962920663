import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "react-bootstrap";
import ErrorPage from "../../ErrorPage";
// import { useErrorBoundary } from "react-error-boundary";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

const VehiclesTestedList = () => {
  // const showErrorBoundary=useErrorBoundary();
  const [userMasterObject, setUserMasterObject] = useState(null);
  const { id, idType } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const device = location.state.device;
  const bu = location.state.bu;
  const makeModelData = location.state.makeModelData;
  const [vehicleData, setVehicleData] = useState([]);
  const [searchTest, setsearchTest] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 50; // Set the number of items per page
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  // Calculate the range of records being displayed
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + vehicleData.length - 1,
    currentPage * itemsPerPage
  );
  const [vehicleCount, setvehicleCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [isLoadedListDataWarning, setIsLoadedListDataWarning] = useState(false);
  const [sortBy, setSortBy] = useState("createdDate");
  const [reverse, setReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const hasToastShownRef = useRef(false);

  // For Active Devices Vehicles Test
  let deviceType = "";

  if (device && device.pageType === "activeDevices") {
    deviceType = "ACTIVE";
  }

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning(
              "UserMasterObject values not found in session storage",
              3000
            );
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
        return <ErrorPage />;
      }
    };

    fetchData();
  }, [navigate]);

  // useEffect(() => {
  //   if (userMasterObject) {
  //     fetchVehicleData(currentPage);
  //   }
  // }, [userMasterObject, currentPage, id, idType]); // Update to watch for changes in currentPage, id, and idType

  // useEffect(() => {
  //   fetchVehicleData(currentPage); // Fetch data with updated state
  // }, [sortBy, reverse, currentPage]); // Fetch when these values change

  useEffect(() => {
    // debugger;
    const fetchVehiclesTestData = async () => {
      if (userMasterObject) {
        await fetchVehicleData(currentPage);
      }
    };

    fetchVehiclesTestData();
  }, [userMasterObject, currentPage, sortBy, reverse, id, idType]);

  useEffect(() => {
    // if (userMasterObject && searchTest === "") {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1);
      fetchVehicleData(1);
    }
    // }, [userMasterObject, searchTest]);
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchVehicleData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    // debugger;
    if (searchTest) {
      setCurrentPage(1);
      fetchVehicleData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchTest]);

  useEffect(() => {
    if (vehicleData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [vehicleData]);

  const fetchVehicleData = async (page) => {
    // debugger;
    // Set loading state to true
    setIsLoadingListData(true);

    try {
      const response = await fetch(
        serverUrl + "vehicle/vehiclestest/list",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ouMasterId: userMasterObject.ouMasterId,
            [idType]: id,
            deviceStatusId: deviceType || "",
            searchText: searchTest || "",
            count: itemsPerPage,
            pagesize: (page - 1) * itemsPerPage, // Calculate offset based on page
            sortBycolumn: sortBy, // Pass the sorting column
            sortByFlag: reverse, // Pass the boolean flag directly
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.vehicleTestList != null &&
            data.entity.vehicleTestList.length > 0 &&
            data.entity.totalCount != null
          ) {
            setVehicleData(data.entity.vehicleTestList);
            setvehicleCount(data.entity.totalCount);
            // Calculate total pages based on totalCount and itemsPerPage
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            // if (isLoadedListDataWarning) {
            // console.error("No vehicle test data found: " + data.entity);
            // toastWarning("No vehicle test data found.", 3000);
            // return;
            // }
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error fetching vehicle data");
      }
    } catch (error) {
      console.error("Error:", error);
      return <ErrorPage />;
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleRowClick = (vehicle, e) => {
    try {
      if (vehicle && vehicle.vehicleId && vehicle.ouMasterId) {
        if (vehicle.wheelCount === 0) {
          e.stopPropagation();
          toastWarning("No Records Found", 3000);
          return;
        } else {
          const stateData = {
            vehicle,
            device,
            bu,
            makeModelData,
          };
          navigate(
            `/${id}/${idType}/wheelsAnalysisReport/${vehicle.vehicleId}/${vehicle.ouMasterId}`,
            {
              state: stateData,
            }
          );
        }
      } else {
        toastWarning("No vehicle tested Records Found", 3000);
        return;
      }
    } catch (error) {
      toastWarning("Please Reload", 3000);
      // console.log(error);
      return <ErrorPage />;
    }
  };

  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }

    // Fetch data for the new page
    // await fetchVehicleData(newPage);

    // Update current page after fetching data
    setCurrentPage(newPage);
  };

  // const handleSearch = () => {
  //   setCurrentPage(1); // Reset current page to 1
  //   fetchVehicleData(1);
  // };
  // const handleSearch = () => {
  //   if (!searchTest.trim()) {
  //     toastWarning("Please enter a search keyword.", 3000);
  //     return;
  //   }

  //   setCurrentPage(1); // Reset to first page
  //   fetchVehicleData(1);
  // };

  //Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  //Get All Records Once For CSV
  const exportList = async () => {
    try {
      const response = await fetch(serverUrl + "vehiclestest/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          [idType]: id,
          searchText: searchTest || "",
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription

          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSort = (newSortBy) => {
    // debugger;
    setReverse((prevReverse) => !prevReverse); // Toggle the current state
    setSortBy(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Vehicles Test Found", 3000);
      setVehicleData([]);
    }
    // Reset to initial values if no data is found
    setvehicleCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    // debugger;
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      // toastWarning("Please enter a search keyword.", 3000);
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}
      <div className="body-pd secondary-color">
        <ToastContainer />
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid px-0 mx-0">
            <div className="row d-flex px-0 mx-0 ">
              <div className="col-lg-12 px-0 mx-0">
                <div className="row px-0 mx-0">
                  <div className="col-md-12 px-0">
                    {/* Header Section Start */}
                    <Header />
                    {/* Header Section End */}
                  </div>
                </div>

                <div
                  className="mx-2 px-0"
                  style={{ backgroundColor: "white", borderRadius: "8px" }}
                >
                  <div className="row mx-0 mt-2 px-2 pb-3">
                    <div className="my-2 px-0 d-flex justify-content-between">
                      <div className="d-flex">
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            className="form-control responsive-input"
                            style={{
                              width: "25vw",
                              borderRadius: "5px",
                            }}
                            placeholder="Search"
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon2"
                            // onChange={(e) => setsearchTest(e.target.value)}
                            onChange={handleInputChange}
                            // value={searchTest}
                            value={searchInputValue}
                          />
                          {searchInputValue && (
                            <FontAwesomeIcon
                              icon={faXmark}
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "30%",
                                color: "gray",
                              }}
                              onClick={() => {
                                setsearchTest("");
                                setSearchInputValue("");
                              }}
                            />
                          )}
                        </div>
                        <div className="input-group-append">
                          <Button
                            variant="primary"
                            className="mx-1 btns search-btn"
                            onClick={handleSearch}
                          >
                            <FontAwesomeIcon icon={faSearch} size="1x" />
                          </Button>
                        </div>
                      </div>
                      <Button
                        variant="primary"
                        className="mx-1 btns"
                        onClick={exportList}
                        disabled={vehicleData.length === 0}
                      >
                        Export
                      </Button>
                    </div>

                    <div className="col-md-12 mx-0 px-0">
                      <div className="card-container test-details">
                        <div className="employee-table">
                          <div
                            className="table-container"
                            style={{ maxHeight: "70vh", overflow: "auto" }}
                          >
                            <table>
                              <thead className="fixed-header">
                                <tr>
                                  <th>Sr. No</th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("vehicleRegistrationNumber")
                                    }
                                  >
                                    Registration Number &nbsp;{" "}
                                    {sortBy === "vehicleRegistrationNumber" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th
                                    className="text-left"
                                    onClick={() =>
                                      handleSort("makeModelMaster.modelName")
                                    }
                                  >
                                    Make_Model &nbsp;
                                    {sortBy === "makeModelMaster.modelName" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}
                                  </th>
                                  <th onClick={() => handleSort("totalKm")}>
                                    Total KM &nbsp;
                                    {sortBy === "totalKm" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}{" "}
                                  </th>
                                  <th onClick={() => handleSort("vehicleYear")}>
                                    Vehicle Year &nbsp;
                                    {sortBy === "vehicleYear" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}{" "}
                                  </th>
                                  <th>Wheels Tested</th>
                                  <th
                                    className="text-left"
                                    onClick={() => handleSort("createdDate")}
                                  >
                                    Created Date &nbsp;
                                    {sortBy === "createdDate" && (
                                      <FontAwesomeIcon
                                        icon={reverse ? faArrowDown : faArrowUp}
                                      />
                                    )}{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {!isLoadingListData &&
                                  vehicleData.length > 0 &&
                                  vehicleData.map((vehicle, index) => (
                                    <tr
                                      className="clickable-row"
                                      onClick={(e) => {
                                        handleRowClick(
                                          {
                                            vehicleId: vehicle.id,
                                            ouMasterId: vehicle.ouMasterObj.id,
                                            wheelCount: vehicle.wheelCount,
                                            MakeName:
                                              vehicle.makeModelMasterObj
                                                .makeName,
                                            ModelName:
                                              vehicle.makeModelMasterObj
                                                .modelName,
                                            RegistrationNumber:
                                              vehicle.vehicleRegistrationNumber,
                                            TotalKm: vehicle.totalKm,
                                            VehicleYear: vehicle.vehicleYear,
                                            CreatedDate: `${new Date(
                                              vehicle.createdDate
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                            })} ${new Date(
                                              vehicle.createdDate
                                            ).toLocaleTimeString("en-US", {
                                              hour: "numeric",
                                              minute: "numeric",
                                              hour12: true,
                                            })}`,
                                          },
                                          e
                                        );
                                      }}
                                      key={vehicle.id}
                                      style={{
                                        borderBottom: "1px solid #ddd",
                                      }}
                                    >
                                      <td>{startingSerialNumber + index}</td>
                                      <td className="text-left">
                                        {vehicle.vehicleRegistrationNumber}
                                      </td>
                                      <td className="text-left">{`${vehicle.makeModelMasterObj.makeName} (${vehicle.makeModelMasterObj.modelName})`}</td>
                                      <td>{vehicle.totalKm}</td>
                                      <td>{vehicle.vehicleYear}</td>
                                      <td>{vehicle.wheelCount}</td>
                                      {/* Format the date using toLocaleDateString */}
                                      <td className="text-left">
                                        {new Date(
                                          vehicle.createdDate
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "2-digit",
                                          year: "numeric",
                                        })}{" "}
                                        {new Date(
                                          vehicle.createdDate
                                        ).toLocaleTimeString("en-US", {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        })}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {!isLoadingListData && vehicleData.length === 0 && (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "15px",
                                }}
                              >
                                <img src={Notfound} alt="Not Found" />
                                <p
                                  style={{
                                    marginTop: "-10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  No Vehicles Test Found
                                </p>
                              </div>
                            )}
                          </div>
                        </div>

                        {!isLoadingListData && vehicleData.length > 0 && (
                          <div className="col-md-12 mt-3 d-flex justify-content-end">
                            <div className="pagination-buttons">
                              <div className="pagination-button">
                                <div
                                  className={`circle ${
                                    currentPage === 1 ||
                                    vehicleData.length === 0
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => handlePagination("prev")}
                                >
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                              </div>

                              <div className="pagination-info">
                                <p>
                                  {/* {rangeStart} - {rangeEnd} of {vehicleCount}{" "} */}
                                  {vehicleData.length > 0 ? rangeStart : 0} -{" "}
                                  {vehicleData.length > 0 ? rangeEnd : 0} of{" "}
                                  {vehicleData.length > 0 ? vehicleCount : 0}{" "}
                                </p>
                              </div>

                              <div className="pagination-button">
                                <div
                                  className={`circle ${
                                    currentPage === totalPages ||
                                    vehicleData.length === 0
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => handlePagination("next")}
                                >
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehiclesTestedList;
