import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faRightFromBracket,
  faXmark,
  faDownload,
  faBars,
  faCircleQuestion,
  faCar,
  faTachometerAlt,
  faCog,
  faPlusCircle,
  faList,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const MenuBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [logoUrl, setLogoUrl] = useState(null);
  const [ouName, setOuName] = useState(null);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const [isRoleTypeSuperAdmin, setIsRoleTypeSuperAdmin] = useState(false);
  const location = useLocation();
  const [isPageTypeHome, setIsPageTypeHome] = useState(false);
  const [showSettingsDropdown, setShowSettingsDropdown] = useState(false);
  const [isPageTypeCreate, setIsPageTypeCreate] = useState(false);
  const [isPageTypeBulkCreateBu, setIsPageTypeBulkCreateBu] = useState(false);
  const [isPageTypeDashboard, setIsPageTypeDashboard] = useState(false);
  const [isPageTypeTests, setIsPageTypeTests] = useState(false);
  const [isPageTypeApplications, setIsPageTypeApplications] = useState(false);
  const [isPageTypeVideos, setIsPageTypeVideos] = useState(false);
  const [isPageTypeRegistered, setIsPageTypeRegistered] = useState(false);
  let hasToastShown = false;
  const [formType, setFormType] = useState(null);
  // Function to extract 'form' query param from URL
  const getFormTypeFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("form");
  };
  const [isPageTypeGetAllMakeModelList, setIsPageTypeGetAllMakeModelList] =
    useState(false);
  const [
    isPageTypeGetAllSubscriptionPlanList,
    setIsPageTypeGetAllSubscriptionPlanList,
  ] = useState(false);
  const [isPageTypeGetAllOUList, setIsPageTypeGetAllOUList] = useState(false);
  const [
    isPageTypeGetAllServiceStationList,
    setIsPageTypeGetAllServiceStationList,
  ] = useState(false);
  const { selectedServiceStation } = location.state || {};
  const [isPageTypeGetAllOuPropertyList, setIsPageTypeGetAllOuPropertyList] =
    useState(false);
  const [isPageTypeGetAllPropertyList, setIsPageTypeGetAllPropertyList] =
    useState(false);
  const [isPageTypeDataManagement, setIsPageTypeDataManagement] =
    useState(false);
  const [isBlurEffectEnable, setIsBlurEffectEnable] = useState(false);

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            // Extract logo URL from session data
            // const logoUrl =
            //   parsedUserData.entity.roleOuObjs[0].ouMasterObj.ouPropertyObjs.find(
            //     (property) => property.propertyName === "Icon"
            //   )?.propertyValue;

            // const ouName =
            //   parsedUserData.entity.roleOuObjs[0].ouMasterObj.ouName; //ouname

            let logoUrl;

            if (selectedServiceStation?.ouMasterObj?.ouName) {
              // Logic to find logoUrl based on ouName
              logoUrl = parsedUserData.entity.roleOuObjs
                .find(
                  (roleOu) =>
                    roleOu.ouMasterObj.ouName ===
                    selectedServiceStation.ouMasterObj.ouName
                )
                ?.ouMasterObj.ouPropertyObjs.find(
                  (property) => property.propertyName === "Icon"
                )?.propertyValue;
            } else {
              // Default logic to find logoUrl
              logoUrl =
                parsedUserData.entity.roleOuObjs[0].ouMasterObj.ouPropertyObjs.find(
                  (property) => property.propertyName === "Icon"
                )?.propertyValue;
            }

            let ouName;

            if (selectedServiceStation?.ouMasterObj?.ouName) {
              ouName = selectedServiceStation?.ouMasterObj?.ouName;
            } else {
              ouName = parsedUserData.entity.roleOuObjs[0].ouMasterObj.ouName; //ouname
            }

            const roleMasterObj =
              parsedUserData.entity.roleOuObjs[0].roleMasterobj;

            if (roleMasterObj && roleMasterObj.roleName === "Super Admin") {
              setIsRoleTypeSuperAdmin(true);
            }

            if (logoUrl) {
              setLogoUrl(logoUrl);
            } else {
              toastWarning("Logo URL not available in session data.", 3000);
              return;
            }

            if (ouName) {
              setOuName(ouName);
            } else {
              toastWarning("OU name not available in session data.", 3000);
              return;
            }
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 998);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const pathname = location.pathname;
    setIsPageTypeHome(pathname === "/home");
    setIsPageTypeCreate(pathname === "/create");
    setIsPageTypeBulkCreateBu(pathname === "/bulkUploadServiceStation");
    setIsPageTypeDashboard(pathname === "/dashboard");
    setIsPageTypeTests(pathname === "/AllvehiclesTestedList");
    setIsPageTypeApplications(pathname === "/appDownloadPage");
    setIsPageTypeVideos(pathname === "/userGuide");
    setIsPageTypeRegistered(pathname === "/registered");
    setIsPageTypeGetAllMakeModelList(pathname === "/getAllMakeModelsList");
    setIsPageTypeGetAllServiceStationList(
      pathname === "/getAllServiceStationList"
    );
    setIsPageTypeGetAllSubscriptionPlanList(
      pathname === "/getAllSubscriptionPlansList"
    );
    setIsPageTypeGetAllOUList(pathname === "/getAllOrganizationsList");
    setIsPageTypeGetAllOuPropertyList(pathname === "/getAllOuPropertyList");
    setIsPageTypeGetAllPropertyList(pathname === "/getAllPropertyList");
    setIsPageTypeDataManagement(pathname === "/dataManagement");
    // setShowSettingsDropdown(false);
  }, [location.pathname]);

  useEffect(() => {
    const form = getFormTypeFromUrl();
    if (form) {
      setFormType(form);
      // console.log("Form Type:", formType);
    }
  }, [location.search]); // Runs when the URL changes

  useEffect(() => {
    // Check if state is passed through navigate
    if (location.state && location.state.isBlurEffectEnable !== undefined) {
      setIsBlurEffectEnable(location.state.isBlurEffectEnable);
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  //Fail Notification
  // const toastWarning = (message, seconds) => {
  //   toast.error(message, {
  //     position: "top-right",
  //     autoClose: seconds,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: false,
  //     draggable: false,
  //     style: { color: "red" },
  //   });
  // };
  const toastWarning = (message, seconds) => {
    if (!hasToastShown) {
      hasToastShown = true;
      toast.error(message, {
        position: "top-right",
        autoClose: seconds,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        style: { color: "red" },
      });
    }
  };

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    // Clear user data from session storage
    sessionStorage.removeItem("user");
    // Redirect to the login page or wherever appropriate
    navigate("/");
    // Close the modal
    setShowLogoutModal(false);
  };

  const cancelLogout = () => {
    // Close the modal
    setShowLogoutModal(false);
  };

  const toggleSettingsDropdown = () => {
    setShowSettingsDropdown((prevState) => !prevState);
  };

  return (
    <>
      <div id="">
        <header className="header" id="header">
          <div className="header_img">
            <FontAwesomeIcon
              icon={faBars}
              onClick={toggleSidebar}
              className="togglebtn toggle-bars"
            />
          </div>
        </header>
        <div className={`l-navbar ${isSidebarOpen ? "show" : ""}`} id="nav-bar">
          <nav className="nav">
            <div>
              <div className="brandName">
                <header>
                  <h4 className="nav_logo-name text-wrap">Suspension Pro</h4>
                </header>
              </div>

              {isPageTypeHome ||
              isPageTypeCreate ||
              isPageTypeBulkCreateBu ||
              isPageTypeGetAllMakeModelList ||
              isPageTypeGetAllSubscriptionPlanList ||
              isPageTypeGetAllServiceStationList ||
              isPageTypeGetAllOuPropertyList ||
              isPageTypeGetAllPropertyList ||
              isPageTypeGetAllOUList ||
              isPageTypeDataManagement ? (
                <div>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={toggleSidebar}
                    className="togglebtn xcross"
                  />
                  <img
                    // src="https://suspension.roadbounce.com/RBEAssets/images/RB_Horiz_Small.png"
                    src="https://suspension.roadbounce.com/RBEAssets/images/RB_Logo_White.png"
                    alt="RoadBounce Brand"
                    className="logo-container"
                    style={{
                      width: "175px",
                      // height: "50px",
                      marginLeft: "25px",
                    }}
                  />
                </div>
              ) : logoUrl ? (
                <div>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={toggleSidebar}
                    className="togglebtn xcross"
                  />
                  <Link to="/dashboard">
                    <img
                      src={logoUrl}
                      alt="OU Brand"
                      className="logo-container"
                      style={{
                        width: "175px",
                        // height: "50px",
                        marginLeft: "25px",
                        height:
                          ouName === "MSIL" || ouName === "MSIL Testing"
                            ? "45px"
                            : "", // Conditional height
                      }}
                    />
                  </Link>
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={toggleSidebar}
                    className="togglebtn xcross"
                  />
                  <img
                    src="https://suspension.roadbounce.com/RBEAssets/images/RB_Horiz_Small.png"
                    alt="RoadBounce Brand"
                    className="logo-container"
                    style={{
                      width: "175px",
                      height: "50px",
                      marginLeft: "25px",
                    }}
                  />
                </div>
              )}

              {!isPageTypeHome &&
                !isPageTypeCreate &&
                !isPageTypeBulkCreateBu &&
                !isPageTypeGetAllMakeModelList &&
                !isPageTypeGetAllServiceStationList &&
                !isPageTypeGetAllSubscriptionPlanList &&
                !isPageTypeGetAllOUList &&
                !isPageTypeGetAllOuPropertyList &&
                !isPageTypeGetAllPropertyList &&
                !isPageTypeDataManagement && (
                  <div className="ouNameContainer">
                    {/* <h5 style={{ marginTop: "10px" }}>{ouName}</h5>{" "} */}
                    <h5 style={{ marginTop: "5px" }}>{ouName}</h5>{" "}
                  </div>
                )}

              <div className="navLink" style={{ fontWeight: "bold" }}>
                {isRoleTypeSuperAdmin && (
                  // <Link
                  //   to="/home"
                  //   className={`nav_link ${isPageTypeHome ? "active" : ""}`}
                  // >
                  //   <FontAwesomeIcon icon={faHome} className="icon-home" />
                  //   <span className="nav_name">Home</span>
                  // </Link>
                  <Link
                    to={isBlurEffectEnable ? "#" : "/home"}
                    className={`nav_link ${isPageTypeHome ? "active" : ""}`}
                    style={{
                      pointerEvents: isBlurEffectEnable ? "none" : "auto",
                      cursor: isBlurEffectEnable ? "not-allowed" : "pointer",
                      filter: isBlurEffectEnable ? "blur(5px)" : "none",
                    }}
                  >
                    <FontAwesomeIcon icon={faHome} className="icon-home" />
                    <span className="nav_name">Home</span>
                  </Link>
                )}

                {isRoleTypeSuperAdmin &&
                  (isPageTypeHome ||
                    isPageTypeCreate ||
                    isPageTypeBulkCreateBu ||
                    isPageTypeGetAllMakeModelList ||
                    isPageTypeGetAllServiceStationList ||
                    isPageTypeGetAllSubscriptionPlanList ||
                    isPageTypeGetAllOUList ||
                    isPageTypeGetAllOuPropertyList ||
                    isPageTypeGetAllPropertyList ||
                    isPageTypeDataManagement) && (
                    // <Link
                    //   to="/dataManagement"
                    //   className={`nav_link ${
                    //     isPageTypeDataManagement ? "active" : ""
                    //   }`}
                    // >
                    //   <FontAwesomeIcon
                    //     icon={faDatabase}
                    //     className="icon-database"
                    //   />
                    //   <span className="nav_name">Data Management</span>
                    // </Link>
                    <Link
                      to={isBlurEffectEnable ? "#" : "/dataManagement"}
                      className={`nav_link ${
                        isPageTypeDataManagement ? "active" : ""
                      }`}
                      style={{
                        pointerEvents: isBlurEffectEnable ? "none" : "auto",
                        cursor: isBlurEffectEnable ? "not-allowed" : "pointer",
                        filter: isBlurEffectEnable ? "blur(5px)" : "none",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faDatabase}
                        className="icon-database"
                      />
                      <span className="nav_name">Data Management</span>
                    </Link>
                  )}

                {!isPageTypeHome &&
                  !isPageTypeCreate &&
                  !isPageTypeBulkCreateBu &&
                  // !isPageTypeRegistered && (
                  !isPageTypeGetAllMakeModelList &&
                  !isPageTypeGetAllServiceStationList &&
                  !isPageTypeGetAllSubscriptionPlanList &&
                  !isPageTypeGetAllOUList &&
                  !isPageTypeGetAllOuPropertyList &&
                  !isPageTypeGetAllPropertyList &&
                  !isPageTypeDataManagement && (
                    <>
                      <Link
                        to="/dashboard"
                        className={`nav_link ${
                          isPageTypeDashboard ? "active" : ""
                        }`}
                      >
                        <FontAwesomeIcon
                          icon={faTachometerAlt}
                          className="icon-home"
                        />
                        <span className="nav_name">Dashboard</span>
                      </Link>
                      <Link
                        to="/AllvehiclesTestedList"
                        className={`nav_link ${
                          isPageTypeTests ? "active" : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faCar} className="icon-home" />
                        <span className="nav_name">Tests</span>
                      </Link>
                    </>
                  )}
                {!isPageTypeHome &&
                  !isPageTypeCreate &&
                  !isPageTypeBulkCreateBu &&
                  // !isPageTypeRegistered && (
                  !isPageTypeGetAllMakeModelList &&
                  !isPageTypeGetAllServiceStationList &&
                  !isPageTypeGetAllSubscriptionPlanList &&
                  !isPageTypeGetAllOUList &&
                  !isPageTypeGetAllOuPropertyList &&
                  !isPageTypeGetAllPropertyList &&
                  !isPageTypeDataManagement && (
                    <>
                      <Link
                        to="/appDownloadPage"
                        className={`nav_link ${
                          isPageTypeApplications ? "active" : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faDownload} />
                        <span className="nav_name">Applications</span>
                      </Link>
                      <Link
                        to="/userGuide"
                        className={`nav_link ${
                          isPageTypeVideos ? "active" : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faCircleQuestion} />
                        <span className="nav_name">Videos</span>
                      </Link>
                    </>
                  )}
                {/* {isPageTypeHome && (
                  <div className="nav_link dropdown">
                    <div
                      onClick={toggleSettingsDropdown}
                      className="dropdown-toggle"
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon icon={faCog} className="icon-home" />
                      <span style={{ marginLeft: "1rem" }} className="nav_name">
                        Settings
                      </span>
                    </div>
                    {showSettingsDropdown && (
                      <div
                        style={{
                          position: "absolute",
                          left: "30px",
                          top: "100%",
                        }}
                      >
                        <Link
                          to="/create?form=serviceStation"
                          className="nav_link"
                        >
                          <span>Service Station</span>
                        </Link>
                        <Link
                          to="/create?form=model"
                          className="nav_link"
                          style={{ marginTop: "-1.5rem" }}
                        >
                          <span>Make Model</span>
                        </Link>
                      </div>
                    )}
                  </div>
                )} */}

                {/* {(isPageTypeHome ||
                  isPageTypeCreate ||
                  isPageTypeBulkCreateBu ||
                  isPageTypeRegistered) && (
                  <>
                    <div className="nav_link dropdown">
                      <div
                        onClick={toggleSettingsDropdown}
                        className="dropdown-toggle"
                        style={{ cursor: "pointer" }}
                      >
                        <FontAwesomeIcon icon={faCog} className="icon-home" />
                        <span
                          style={{ marginLeft: "1rem" }}
                          className="nav_name"
                        >
                          Settings
                        </span>
                      </div>
                      {showSettingsDropdown && (
                        <div
                          style={{
                            position: "absolute",
                            left: "30px",
                            top: "100%",
                          }}
                        >
                          <Link
                            to="/create?form=serviceStation"
                            className={`nav_link ${
                              (isPageTypeCreate &&
                                formType === "serviceStation") ||
                              isPageTypeBulkCreateBu
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Service Station</span>
                          </Link>
                          <Link
                            to="/registered?form=model"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              (isPageTypeRegistered && formType === "model") ||
                              (isPageTypeCreate && formType === "model")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Make Model</span>
                          </Link>

                          <Link
                            to="/registered?form=subscriptionPlan"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              (isPageTypeRegistered &&
                                formType === "subscriptionPlan") ||
                              (isPageTypeCreate &&
                                formType === "subscriptionPlan")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Subscription Plan</span>
                          </Link>
                        </div>
                      )}
                    </div>
                  </>
                )} */}

                {(isPageTypeHome ||
                  isPageTypeCreate ||
                  isPageTypeBulkCreateBu ||
                  isPageTypeGetAllMakeModelList ||
                  isPageTypeGetAllServiceStationList ||
                  isPageTypeGetAllSubscriptionPlanList ||
                  isPageTypeGetAllOUList ||
                  isPageTypeGetAllOuPropertyList ||
                  isPageTypeGetAllPropertyList ||
                  isPageTypeDataManagement) && (
                  <>
                    <div className="nav_link dropdown">
                      <div
                        // onClick={toggleSettingsDropdown}
                        // className="dropdown-toggle"
                        // style={{ cursor: "pointer" }}
                        onClick={
                          isBlurEffectEnable ? null : toggleSettingsDropdown
                        }
                        className="dropdown-toggle"
                        style={{
                          cursor: isBlurEffectEnable
                            ? "not-allowed"
                            : "pointer",
                          pointerEvents: isBlurEffectEnable ? "none" : "auto",
                          filter: isBlurEffectEnable ? "blur(5px)" : "none",
                        }}
                      >
                        <FontAwesomeIcon icon={faCog} className="icon-home" />
                        <span
                          style={{ marginLeft: "1rem" }}
                          className="nav_name"
                        >
                          Settings
                        </span>
                      </div>
                      {showSettingsDropdown && (
                        <div
                          id="setting_dropDown"
                          style={{
                            position: "absolute",
                            left: "30px",
                            top: "100%",
                          }}
                        >
                          <Link
                            to="/getAllServiceStationList"
                            className={`nav_link ${
                              isPageTypeGetAllServiceStationList ||
                              (isPageTypeCreate &&
                                formType === "serviceStation") ||
                              isPageTypeBulkCreateBu
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Service Station</span>
                          </Link>
                          <Link
                            to="/getAllMakeModelsList"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              isPageTypeGetAllMakeModelList ||
                              (isPageTypeCreate &&
                                location.search === "?form=model")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Make Model</span>
                          </Link>

                          <Link
                            to="/getAllSubscriptionPlansList"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              isPageTypeGetAllSubscriptionPlanList ||
                              (isPageTypeCreate &&
                                location.search === "?form=subscriptionPlan")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Subscription Plan</span>
                          </Link>

                          <Link
                            to="/getAllOrganizationsList"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              isPageTypeGetAllOUList ||
                              (isPageTypeCreate &&
                                location.search === "?form=organization")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Organization Unit</span>
                          </Link>

                          <Link
                            to="/getAllPropertyList"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              isPageTypeGetAllPropertyList ||
                              (isPageTypeCreate &&
                                location.search === "?form=PropertyMaster")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Properties</span>
                          </Link>

                          <Link
                            to="/getAllOuPropertyList"
                            style={{ marginTop: "-1.5rem" }}
                            className={`nav_link ${
                              isPageTypeGetAllOuPropertyList ||
                              (isPageTypeCreate &&
                                location.search === "?form=ouProperty")
                                ? "active"
                                : ""
                            }`}
                          >
                            <span>Properties Assignment</span>
                          </Link>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* {(isPageTypeCreate ||
                  isPageTypeBulkCreateBu ||
                  isPageTypeRegistered) && (
                  <>
                    <Link
                      to={`/create?form=${formType || "defaultFormType"}`} // Pass formType in the URL
                      className={`nav_link ${
                        isPageTypeCreate || isPageTypeBulkCreateBu
                          ? "active"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faPlusCircle} />
                      <span className="nav_name">Create</span>
                    </Link>
                    // Only show "Registered" if formType is "model" 
                    {formType === "model" && (
                      <Link
                        to={`/registered?form=${formType}`} // Pass formType to the Registered link
                        className={`nav_link ${
                          isPageTypeRegistered ? "active" : ""
                        }`}
                      >
                        <FontAwesomeIcon icon={faList} />
                        <span className="nav_name">Registered</span>
                      </Link>
                    )}
                  </>
                )} */}
              </div>
            </div>
            <div style={{ filter: isBlurEffectEnable ? "blur(5px)" : "none" }}>
              <div
                className="nav_link"
                // onClick={handleLogout}
                onClick={isBlurEffectEnable ? null : handleLogout}
                style={{
                  pointerEvents: isBlurEffectEnable ? "none" : "auto",
                  cursor: isBlurEffectEnable ? "not-allowed" : "pointer",
                }}
              >
                <FontAwesomeIcon icon={faRightFromBracket} />
                <span className="nav_name">Logout</span>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* Logout Confirmation Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        className="modal-dialog-centered"
        animation={true}
        backdrop="static"
        dialogClassName="modal-full-screen"
      >
        <Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to log out?</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={cancelLogout}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={confirmLogout}>
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MenuBar;
