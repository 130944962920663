import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";
import Update from "./Update";

const GetAllOrganizationsList = () => {
  const formType = "organization"; // Extract formType from queryParams
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [searchTest, setsearchTest] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [ouMasterData, setOuMasterData] = useState([]);
  const [ouMasterCount, setOuMasterCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 50;
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + ouMasterData.length - 1,
    currentPage * itemsPerPage
  );
  const [ouMasterDataSorting, setOuMasterDataSorting] = useState("createdDate");
  const [ouMasterDataReverse, setOuMasterDataReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedOu, setSelectedOu] = useState(null);
  const hasToastShownRef = useRef(false);

  // Get Data From Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchListData = async () => {
      if (userMasterObject) {
        await fetchOuMasterData(currentPage);
      }
    };
    fetchListData();
  }, [userMasterObject, currentPage, ouMasterDataSorting, ouMasterDataReverse]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1); // Reset current page to 1 when search is cleared
      fetchOuMasterData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchOuMasterData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    if (ouMasterData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [ouMasterData]);

  useEffect(() => {
    if (searchTest && userMasterObject) {
      setCurrentPage(1);
      fetchOuMasterData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchTest]);

  // Fetch data from the API
  const fetchOuMasterData = async (page) => {
    // Set loading state to true
    setIsLoadingListData(true);
    try {
      const response = await fetch(serverUrl + "/get/organization", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: searchTest || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage, // Calculate offset based on page
          sortBycolumn: ouMasterDataSorting, // Pass the sorting column
          sortByFlag: ouMasterDataReverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.OuResultsList != null &&
            data.entity.OuResultsList.length > 0
          ) {
            setOuMasterData(data.entity.OuResultsList || []);
            setOuMasterCount(data.entity.totalCount || 0);
            // Calculate total pages based on totalCount and itemsPerPage
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching MakeModel data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleCreateButtonClick = () => {
    navigate(`/create?form=${formType || "defaultFormType"}`);
  };

  const handleUpdateClick = (item) => {
    // debugger;
    setSelectedOu(item);
    setShowUpdateForm(true);
  };

  const handleUpdateComplete = () => {
    setCurrentPage(1);
    fetchOuMasterData(1);
  };

  // Function to export OU List data in CSV
  const exportOuList = async () => {
    try {
      const response = await fetch(serverUrl + "ou/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: searchTest || "", // If you have search functionality
          sortBycolumn: ouMasterDataSorting, // Pass the sorting column
          sortByFlag: ouMasterDataReverse, // Pass the boolean flag for sorting direction
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV file in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          // Show errors if they exist
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error exporting Ou List CSV");
        toastWarning("Error exporting Ou List CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Pagination Function
  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }
    setCurrentPage(newPage);
  };

  const handleOuMasterSort = (newSortBy) => {
    setOuMasterDataReverse((prevReverse) => !prevReverse); // Toggle the current state
    setOuMasterDataSorting(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  // Function to handle no Vehicles Test found
  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Organizations Unit Found", 3000);
      setOuMasterData([]);
    }
    // Reset to initial values if no data is found
    setOuMasterCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <div className="px-0 mx-0">
            <ToastContainer />
            <div className="row px-0 mx-0 d-flex">
              <div className="col-md-12 mx-0 px-0">
                <Header />
                <div className="row px-2 mx-0">
                  <div
                    className="px-0 my-2"
                    style={{ backgroundColor: "white", borderRadius: "8px" }}
                  >
                    <div
                      className="card-container-test mx-0 mt-2 px-2 pb-3 "
                      style={{ maxheight: "100" }}
                    >
                      <div className="px-0 d-flex justify-content-between">
                        <div className="d-flex mb-2">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={searchInputValue}
                              onChange={handleInputChange}
                              style={{
                                width: "25vw",
                                borderRadius: "5px",
                              }}
                            />
                            {searchInputValue && (
                              <FontAwesomeIcon
                                icon={faXmark}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "30%",
                                  color: "gray",
                                }}
                                onClick={() => {
                                  setsearchTest("");
                                  setSearchInputValue("");
                                }}
                              />
                            )}
                          </div>

                          <Button
                            variant="primary"
                            onClick={handleSearch}
                            className="btns mx-1"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>

                        <div className="justify-content-end">
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={handleCreateButtonClick}
                          >
                            Create
                          </Button>
                          <Button
                            variant="primary"
                            className="mx-1 mb-2 btns"
                            onClick={exportOuList}
                            disabled={ouMasterData.length === 0}
                          >
                            Export
                          </Button>
                        </div>
                      </div>

                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <table>
                            <thead className="fixed-header">
                              <tr>
                                <th>Sr. No</th>
                                <th
                                  className="text-left"
                                  onClick={() => handleOuMasterSort("ouName")}
                                >
                                  OU Name{" "}
                                  {ouMasterDataSorting === "ouName" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouMasterDataReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleOuMasterSort(
                                      "subsMaster.planDescription"
                                    )
                                  }
                                >
                                  Plan Description{" "}
                                  {ouMasterDataSorting ===
                                    "subsMaster.planDescription" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouMasterDataReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-center"
                                  onClick={() =>
                                    handleOuMasterSort("subsMaster.duration")
                                  }
                                >
                                  Plan Duration (In Days) &nbsp;
                                  {ouMasterDataSorting ===
                                    "subsMaster.duration" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouMasterDataReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleOuMasterSort("expiryDate")
                                  }
                                >
                                  Expiry Date{" "}
                                  {ouMasterDataSorting === "expiryDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouMasterDataReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleOuMasterSort("createdDate")
                                  }
                                >
                                  Created Date &nbsp;
                                  {ouMasterDataSorting === "createdDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouMasterDataReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleOuMasterSort("updatedDate")
                                  }
                                >
                                  Updated Date &nbsp;
                                  {ouMasterDataSorting === "updatedDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        ouMasterDataReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}{" "}
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isLoadingListData &&
                                ouMasterData.length > 0 &&
                                ouMasterData.map((ouItem, index) => (
                                  <tr
                                    className="clickable-row"
                                    key={ouItem.id}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                      //   backgroundColor: ouItem.isHighlight
                                      //     ? "#f5f5a5"
                                      //     : "transparent", // Highlight color if isHighlight is true
                                      backgroundColor: ouItem.isDisabled
                                        ? // ? "#D95336"
                                          "#FF7171"
                                        : ouItem.isHighlight
                                        ? "#f5f5a5"
                                        : "transparent", // Default background color if neither is true
                                    }}
                                  >
                                    <td>{startingSerialNumber + index}</td>
                                    <td className="text-left">
                                      {ouItem.ouName}
                                    </td>
                                    <td className="text-left">
                                      {ouItem.subsMasterObj.planDescription}
                                    </td>
                                    <td className="text-center">
                                      {ouItem.subsMasterObj.duration}
                                    </td>
                                    <td className="text-left">
                                      {new Date(
                                        ouItem.expiryDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="text-left">
                                      {new Date(
                                        ouItem.createdDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="text-left">
                                      {ouItem.updatedDate
                                        ? new Date(
                                            ouItem.updatedDate
                                          ).toLocaleDateString("en-GB")
                                        : "-"}
                                    </td>
                                    <td onClick={(e) => e.stopPropagation()}>
                                      <button
                                        style={{
                                          color: "black",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          handleUpdateClick(ouItem)
                                        }
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {!isLoadingListData && ouMasterData.length === 0 && (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "15px",
                              }}
                            >
                              <img src={Notfound} alt="Not Found" />
                              <p
                                style={{
                                  marginTop: "-10px",
                                  fontWeight: "bold",
                                }}
                              >
                                No Organizations Unit Found
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {!isLoadingListData && ouMasterData.length > 0 && (
                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                          <div className="pagination-buttons">
                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === 1 || ouMasterData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("prev")}
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>

                            <div className="pagination-info">
                              <p>
                                {ouMasterData.length > 0 ? rangeStart : 0} -{" "}
                                {ouMasterData.length > 0 ? rangeEnd : 0} of{" "}
                                {ouMasterData.length > 0 ? ouMasterCount : 0}{" "}
                              </p>
                            </div>

                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === totalPages ||
                                  ouMasterData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("next")}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Render Update Component */}
      {showUpdateForm && (
        <Update
          formType={formType}
          selectedOu={selectedOu}
          showUpdateForm={showUpdateForm}
          setShowUpdateForm={setShowUpdateForm}
          handleUpdateComplete={handleUpdateComplete}
        />
      )}
    </>
  );
};

export default GetAllOrganizationsList;
