import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";
import Update from "./Update";

const GetAllServiceStationList = () => {
  const location = useLocation();
  const formType = "serviceStation";
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [searchTest, setsearchTest] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [buData, setBuData] = useState([]);
  const [buCount, setbuCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 50;
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + buData.length - 1,
    currentPage * itemsPerPage
  );
  const [buSorting, setBuSorting] = useState("createdDate");
  const [buReverse, setBuReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [ouOptions, setOuOptions] = useState([]);
  const [selectedDropdownOuId, setSelectedDropdownOuId] = useState();
  const [vehicleTestCount, setVehicleTestCount] = useState(0);
  const [deletingListData, setDeletingListData] = useState(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isDeleteReConfirmationOpen, setDeleteReConfirmationOpen] =
    useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedServiceStation, setSelectedServiceStation] = useState(null);
  const hasToastShownRef = useRef(false);

  // Get Data From Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });

            if (parsedUserData?.entity?.roleOuObjs?.length > 0) {
              const uniqueOUs = parsedUserData.entity.roleOuObjs.map(
                (role) => ({
                  id: role.ouMasterObj.id,
                  ouName: role.ouMasterObj.ouName,
                })
              );
              const uniqueOUsFiltered = uniqueOUs.filter(
                (ou, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t.id === ou.id && t.ouName === ou.ouName
                  )
              );
              setOuOptions(uniqueOUsFiltered);
            }
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchListData = async () => {
      if (userMasterObject) {
        await fetchBuData(currentPage);
      }
    };
    fetchListData();
  }, [userMasterObject, currentPage, buSorting, buReverse]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1); // Reset current page to 1 when search is cleared
      fetchBuData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchBuData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    if (buData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [buData]);

  useEffect(() => {
    if (searchTest && userMasterObject) {
      setCurrentPage(1);
      fetchBuData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchTest]);

  useEffect(() => {
    if (selectedDropdownOuId !== null) {
      setCurrentPage(1);
      fetchBuData(1); // Call the function when the organization changes
    }
    setCurrentPage(1);
    fetchBuData(1);
  }, [selectedDropdownOuId]);

  // Fetch data from the API
  const fetchBuData = async (page) => {
    setIsLoadingListData(true);
    try {
      const response = await fetch(serverUrl + "serviceStation/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId,
          searchText: searchTest || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage, // Calculate offset based on page
          sortBycolumn: buSorting, // Pass the sorting column
          sortByFlag: buReverse, // Pass the boolean flag directly
          pageType: "getAllServiceStation",
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (data.entity.buList != null && data.entity.buList.length > 0) {
            setBuData(data.entity.buList || []);
            setbuCount(data.entity.totalCount || 0);
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching MakeModel data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleCreateButtonClick = () => {
    navigate(`/create?form=${formType || "defaultFormType"}`);
  };

  const openDeleteConfirmation = (e, dataToDelete) => {
    e.stopPropagation();
    setDeletingListData(dataToDelete);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  //Deletion Confirm Function
  const confirmDelete = async (e) => {
    if (deletingListData) {
      toastSuccess("Successfully Deleted");
      try {
        const response = await fetch(serverUrl + "/serviceStation/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            buMasterId: deletingListData.buMasterId,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.entity != null) {
            const updatedBu = buData.filter(
              (bu) => bu.id !== deletingListData.id
            );
            setBuData([...updatedBu]);
            setCurrentPage(1);
            fetchBuData(1);
          } else if (data.errors != null) {
            let errorDescription = data.errors.errorDescription;
            toastWarning(errorDescription, 3000);
            return;
          } else {
            toastWarning("Something Went Wrong", 3000);
            return;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleUpdateClick = (item) => {
    setSelectedServiceStation(item);
    setShowUpdateForm(true);
  };

  const handleUpdateComplete = () => {
    setCurrentPage(1);
    fetchBuData(1);
  };

  // Function to export Model List data in CSV
  const exportBuList = async () => {
    try {
      const response = await fetch(serverUrl + "servicestations/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId,
          searchText: searchTest || "",
          sortBycolumn: buSorting,
          sortByFlag: buReverse,
          pageType: "getAllServiceStation",
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription

          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Pagination Function
  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }
    setCurrentPage(newPage);
  };

  const handleBuSort = (newSortBy) => {
    setBuReverse((prevReverse) => !prevReverse); // Toggle the current state
    setBuSorting(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  // Function to handle no Vehicles Test found
  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No service stations found", 3000);
      setBuData([]);
    }
    // Reset to initial values if no data is found
    setbuCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  const handleDeviceViewButtonClick = (item) => {
    debugger;
    navigate("/usedDevices", { state: { selectedServiceStation: item } });
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <div className="px-0 mx-0">
            <ToastContainer />
            <div className="row px-0 mx-0 d-flex">
              <div className="col-md-12 mx-0 px-0">
                <Header />
                <div className="row px-2 mx-0">
                  <div
                    className="px-0 my-2"
                    style={{ backgroundColor: "white", borderRadius: "8px" }}
                  >
                    <div
                      className="card-container-test mx-0 mt-2 px-2 pb-3 "
                      style={{ maxheight: "100" }}
                    >
                      <div className="px-0 d-flex justify-content-between">
                        <div className="d-flex mb-2">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={searchInputValue}
                              onChange={handleInputChange}
                              style={{
                                width: "25vw",
                                borderRadius: "5px",
                              }}
                            />
                            {searchInputValue && (
                              <FontAwesomeIcon
                                icon={faXmark}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "30%",
                                  color: "gray",
                                }}
                                onClick={() => {
                                  setsearchTest("");
                                  setSearchInputValue("");
                                }}
                              />
                            )}
                          </div>

                          <Button
                            variant="primary"
                            onClick={handleSearch}
                            className="btns mx-1"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center", // Aligns items vertically in the center
                            marginRight: "auto",
                          }}
                        >
                          <label
                            htmlFor="organizationName"
                            style={{
                              marginRight: "10px",
                              fontWeight: "bold",
                              marginLeft: "20px",
                            }}
                          >
                            Organization:
                          </label>
                          <Select
                            defaultValue={
                              selectedDropdownOuId
                                ? {
                                    value: selectedDropdownOuId,
                                    label:
                                      ouOptions.find(
                                        (ou) => ou.id === selectedDropdownOuId
                                      )?.ouName || "All",
                                  }
                                : { value: null, label: "All" }
                            }
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: "150px", // Set your desired width for the control (input area)
                              }),
                              menu: (provided) => ({
                                ...provided,
                                width: "150px",
                                zIndex: "999",
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                background: state.isSelected
                                  ? "transparent"
                                  : provided.background,
                                color: state.isSelected ? "black" : "black",
                              }),
                            }}
                            options={[
                              { value: null, label: "All" }, // Add the "All" option
                              ...ouOptions.map((ou) => ({
                                value: ou.id,
                                label: ou.ouName,
                              })),
                            ]}
                            placeholder="Select"
                            isSearchable
                            onChange={(selectedOption) => {
                              if (selectedOption.value === null) {
                                setSelectedDropdownOuId(null); // If "All" is selected, set the value to null
                              } else {
                                setSelectedDropdownOuId(selectedOption.value); // Set the selected organization ID
                              }
                            }}
                          />
                        </div>

                        <div className="justify-content-end">
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={handleCreateButtonClick}
                          >
                            Create
                          </Button>
                          <Button
                            variant="primary"
                            className="mx-1 mb-2 btns"
                            onClick={exportBuList}
                            disabled={buData.length === 0}
                          >
                            Export
                          </Button>
                        </div>
                      </div>

                      {/* Delete Modal */}
                      <Modal
                        show={isDeleteConfirmationOpen}
                        onHide={closeDeleteConfirmation}
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            Are you sure you want to delete the{" "}
                            <strong>
                              {deletingListData?.buName || ""} [
                              {deletingListData?.buCode || ""}]
                            </strong>{" "}
                            Service Station, located at
                            <strong>
                              {" "}
                              {deletingListData?.buLocation || ""}
                            </strong>
                            ?
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btns"
                            variant="secondary"
                            onClick={closeDeleteConfirmation}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btns"
                            variant="danger"
                            onClick={(e) => {
                              closeDeleteConfirmation();
                              setDeleteReConfirmationOpen(true);
                              closeDeleteConfirmation();
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* Re-confirmation Modal */}
                      <Modal
                        show={isDeleteReConfirmationOpen}
                        onHide={() => setDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Reconfirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            Are you sure you want to delete the{" "}
                            <strong>
                              {deletingListData?.buName || ""} [
                              {deletingListData?.buCode || ""}]
                            </strong>{" "}
                            Service Station, along with its{" "}
                            <strong>
                              {deletingListData?.deviceCount || "0"}
                            </strong>{" "}
                            devices?
                            <br /> Note that it has{" "}
                            <strong>
                              {deletingListData?.vehicleTestObj.vehicleCount ||
                                "0"}
                            </strong>{" "}
                            vehicles tested.
                            <br />
                            This action is irreversible.
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              confirmDelete(e);

                              setDeleteReConfirmationOpen(false);
                            }}
                          >
                            Confirm Delete
                          </Button>
                          <Button
                            variant="secondary"
                            onClick={() => setDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                          >
                            Cancel
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <table>
                            <thead className="fixed-header">
                              <tr>
                                <th>Sr. No</th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("buName")}
                                >
                                  Name &nbsp;
                                  {buSorting === "buName" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("buLocation")}
                                >
                                  Location &nbsp;
                                  {buSorting === "buLocation" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("buChannel")}
                                >
                                  Channel &nbsp;
                                  {buSorting === "buChannel" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("buRegion")}
                                >
                                  Region &nbsp;
                                  {buSorting === "buRegion" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("buCode")}
                                >
                                  Code &nbsp;
                                  {buSorting === "buCode" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleBuSort("ouMaster.ouName")
                                  }
                                >
                                  Organization Name &nbsp;
                                  {buSorting === "buCode" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("testCount")}
                                >
                                  Vehicles Tested &nbsp;
                                  {buSorting === "testCount" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th>Device Count</th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("createdDate")}
                                >
                                  Created Date &nbsp;
                                  {buSorting === "createdDate" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleBuSort("updatedDate")}
                                >
                                  Updated Date &nbsp;
                                  {buSorting === "updatedDate" && (
                                    <FontAwesomeIcon
                                      icon={buReverse ? faArrowDown : faArrowUp}
                                    />
                                  )}{" "}
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isLoadingListData &&
                                buData.length > 0 &&
                                buData.map((bu, index) => (
                                  <tr
                                    className="clickable-row"
                                    key={bu.buMasterId}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                      backgroundColor: bu.isHighlight
                                        ? "#f5f5a5"
                                        : "transparent", // Highlight color if isHighlight is true
                                    }}
                                  >
                                    <td>{startingSerialNumber + index}</td>
                                    <td className="text-left">{bu.buName}</td>
                                    <td className="text-left">
                                      {bu.buLocation}
                                    </td>
                                    <td className="text-left">
                                      {bu.buChannel}
                                    </td>
                                    <td className="text-left">{bu.buRegion}</td>
                                    <td className="text-left">{bu.buCode}</td>
                                    <td className="text-left">
                                      {bu.ouMasterObj.ouName}
                                    </td>
                                    <td>{bu.vehicleTestObj.vehicleCount}</td>
                                    <td className="text-center">
                                      <Button
                                        className="mx-1 btns"
                                        variant="primary"
                                        disabled={bu.deviceCount === 0}
                                        onClick={(e) =>
                                          handleDeviceViewButtonClick(bu)
                                        }
                                      >
                                        View [{bu.deviceCount}]
                                      </Button>
                                    </td>
                                    <td className="text-left">
                                      {new Date(
                                        bu.createdDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="text-left">
                                      {bu.updatedDate
                                        ? new Date(
                                            bu.updatedDate
                                          ).toLocaleDateString("en-GB")
                                        : "-"}
                                    </td>

                                    <td onClick={(e) => e.stopPropagation()}>
                                      <div
                                        style={{ display: "flex", gap: "25px" }}
                                      >
                                        <button
                                          style={{
                                            color: "black",
                                            border: "none",
                                          }}
                                          onClick={() => handleUpdateClick(bu)}
                                        >
                                          <FontAwesomeIcon icon={faPen} />
                                        </button>
                                        <button
                                          className="delete-btn btns"
                                          style={{
                                            color: "black",
                                            border: "none",
                                          }}
                                          onClick={(e) =>
                                            openDeleteConfirmation(e, bu)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {!isLoadingListData && buData.length === 0 && (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "15px",
                              }}
                            >
                              <img src={Notfound} alt="Not Found" />
                              <p
                                style={{
                                  marginTop: "-10px",
                                  fontWeight: "bold",
                                }}
                              >
                                No service stations found
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {!isLoadingListData && buData.length > 0 && (
                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                          <div className="pagination-buttons">
                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === 1 || buData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("prev")}
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>

                            <div className="pagination-info">
                              <p>
                                {buData.length > 0 ? rangeStart : 0} -{" "}
                                {buData.length > 0 ? rangeEnd : 0} of{" "}
                                {buData.length > 0 ? buCount : 0}{" "}
                              </p>
                            </div>

                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === totalPages ||
                                  buData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("next")}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Render Update Component */}
      {showUpdateForm && (
        <Update
          formType={formType}
          selectedServiceStation={selectedServiceStation}
          showUpdateForm={showUpdateForm}
          setShowUpdateForm={setShowUpdateForm}
          handleUpdateComplete={handleUpdateComplete}
        />
      )}
    </>
  );
};

export default GetAllServiceStationList;
